







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AnswerText extends Vue {
  @Prop({ required: false, default: "--", type: String })
  private answerValue: string;
}
